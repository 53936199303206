import axios from 'axios';

export default class Axios 
{
    static ajax(options)
    {
        
        axios({
            method: options.method || 'post',
            url : options.url,
            data : options.params,
        }).then( function( response ) {
            if( options.callback!==undefined ) 
            {
                options.callback.call( this, response.data, options.params, options.backParam );
            }
        }).catch( function( error ) {
            console.error( error);
        });
    }
}