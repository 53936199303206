export const throttle = (func, limit) => {
    let lastTime = 0; // 记录上一次执行的时间
    return function() {
        const currentTime = Date.now(); // 获取当前时间
        if (currentTime - lastTime >= limit) {
            func.apply(this, arguments); // 超过限制时间则立即执行函数
            lastTime = currentTime; // 更新上一次执行的时间
        }
    };
}
export const debounce = (func, delay) => {
    let timer; // 创建一个计时器变量
    
    return function() {
        clearTimeout(timer); // 清除之前设置的计时器
        
        timer = setTimeout(() => {
            func.apply(this, arguments); // 在指定的延迟后调用传入的函数
        }, delay);
    };
}