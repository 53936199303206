
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import './index.scss';

const BecomePartner = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { index: '001', title: t('sponsorship.title1'), content: t('sponsorship.content1') },
        { index: '002', title: t('sponsorship.title2'), content: t('sponsorship.content2') },
        { index: '003', title: t('sponsorship.title3'), content: t('sponsorship.content3') },
        { index: '004', title: t('sponsorship.title4'), content: t('sponsorship.content4') },
    ];

    useEffect(() => {
        document.title = t('menu.thirdMenu.title12')
    }, [])

    return <>
        <div className="partner_header">
            <div className="partner_header_title">{t('menu.thirdMenu.title12')}</div>
        </div>
        <div className="partner_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('menu.thirdMenu.title12')}</div>
            </div>
            <div className="partner_model2">
                <ul>
                    {
                        list.map((item, index) => {
                            return <li key={index}>
                                <div className="partner_model2_item1">{item.index}</div>
                                <div className="partner_model2_item2">{item.title}</div>
                                <div className="partner_model2_item3">{item.content}</div>
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    </>   
}

export default BecomePartner;