import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";


const Contacts = () => 
{
    const history = useHistory();
    const { t } = useTranslation();

    const contentList = [
        { title: t('menu.firstMenu.title4'), img: require('../img/contractUs/email.png'), email: 'contact@connect.com.cn' },
        { title: t('contactUs.title1'), img: require('../img/contractUs/video.png'), email: 'press@connect.com.cn' },
        { title: t('contactUs.title2'), img: require('../img/contractUs/connect.png'), email: 'business@connect.com.cn' },
        { title: t('contactUs.title3'), img: require('../img/contractUs/list.png'), email: 'cn1000miglia@connect.com.cn' },
    ];
    
    useEffect(() => {
        document.title = t('menu.firstMenu.title4')
    }, [])

    return <div className="contact_wrap">
        <div className="contact_header_box_bg">
            <p>{t('menu.firstMenu.title4')}</p>
        </div>
        <div className="contact_content">
            
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('menu.firstMenu.title4')}</div>
            </div>
            <div className="contact_content_text">
                <ul>
                    {
                        contentList.map((item, index) => {
                            return <li key={index}>
                                <img src={item.img} alt="" />
                                <div className="contact_content_text_item_title">{item.title}</div>
                                <div className="contact_content_text_item_email">{item.email}</div>
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    </div>
}

export default Contacts;