import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const About = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { 
          title: t('aboutUs.title2'),
          contentList: [
            { content: t('aboutUs.content2'), img: require('../../img/aboutUs1.jpg') }
          ],  
        },
        
        { 
            title: t('aboutUs.title3'),
            contentList: [
              { contentType: 'render', content: t('aboutUs.content3'), imgList: [
                { img: require('../../img/aboutUs2.jpg'), width: 550, },
                { img: require('../../img/aboutUs3.jpg'), width: 370, height: 370 },
              ] }
            ],  
        },

        { 
            title: t('aboutUs.title4'),
            contentList: [
              { contentType: 'render', content: t('aboutUs.content4'), img: require('../../img/aboutUs4.jpg') }
            ],  
        },
        { 
          title: t('aboutUs.title5'),
          contentList: [
            { content: t('aboutUs.content5') }
          ],  
        },

        { 
            title: t('aboutUs.title6'),
            contentList: [
              { contentType: 'render', content: t('aboutUs.content6'), img: require('../../img/aboutUs5.jpg') }
            ],  
        },

        { 
            title: t('aboutUs.title7'),
            contentList: [
              { content: t('aboutUs.content7') }
            ],  
        },
        {
            contentList: [
              { content: t('aboutUs.title8') },
              { content: `<a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://weibo.com/u/7917857464" target="blank">${t('homePage.social.title1')}</a> 
                <a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://v.douyin.com/iMhoLuEP/" target="blank">${t('homePage.social.title2')}</a> 
                <a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://space.bilibili.com/3546673135487037" target="blank">${t('homePage.social.title3')}</a> 
                <a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://www.xiaohongshu.com/user/profile/66308b1e0000000007004905" target="blank">${t('homePage.social.title4')}</a>`, contentType: 'render',  }, 
              { content: `${t('media.title5')} contact@connect.com.cn` },
              { contentType: 'function', content: () => <a onClick={() => history.push('/registro?lang=cn')} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>{t('aboutUs.content8')}</a>},
            ],  
        },
    ];

    useEffect(() => {
      document.title = t('aboutUs.title1')
  }, [])

    return <>
        <div className="public_header">
          <img src={require('../../img/about_bg.jpg')} alt="" />
          <div className="public_header_title">{t('aboutUs.title1')}</div>
        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('aboutUs.title1')}</div>
            </div>
            <div className="bg-fff">
                <ArticleComs list={list} />
            </div>
        </div>
    </>   
}

export default About;
