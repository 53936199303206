import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const EventsPressConference = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        {
            contentList: [
                { content: t('conference.content1'), },
                { content: t('conference.content2'), },
                { content: t('conference.content3'), img: require('../../img/press_briefing1.jpg') },
            ]
        },
        {
            contentList: [
                { content: t('conference.content4'), img: require('../../img/press_briefing2.jpg') },
            ],    
        },
        {
            contentList: [
                { content: t('conference.content5'), img: require('../../img/press_briefing3.jpg') },
            ],    
        },
        {
            contentList: [
                { content: t('conference.content6') },
                { content: t('conference.content7'), img: require('../../img/press_briefing4.jpg') },
            ],    
        },
    ];

    const listIntroduce = [
        {
            title: t('conference.title8'),
            contentList: [
                { content: t('conference.content8') },
            ],
        },
        {
            title: t('conference.title9'),
            contentList: [
                { content: t('conference.content9') },
            ],
        },
    ];

    useEffect(() => {
        document.title = t('conference.title')
    }, [])

    return <>
        <div className="public_header">
            <div className="public_header_title">{t('conference.title')}</div>
        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('conference.title')}</div>
            </div>
            <div className="bg-fff">
                <ArticleComs list={list} />
                <div className="line_box">
                    <div className="line"></div>
                </div>
                <ArticleComs list={listIntroduce} />
            </div>
        </div>
    </>   
}

export default EventsPressConference;
