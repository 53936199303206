import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const AboutFCCC = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { 
            title: t('fccc.title1'),
            contentList: [
                { content: t('fccc.content1.content1'), },
                { content: t('fccc.content1.content2'), },
                { content: t('fccc.content1.content3'), },
                { content: t('fccc.content1.content4'), },
                { content: t('fccc.content1.content5'), },
            ],    
        },
        { 
            title: t('fccc.title2'),
            contentList: [
                { content: t('fccc.content2.content1'), },
                { content: t('fccc.content2.content2'), },
                { content: t('fccc.content2.content3'), },
                { content: t('fccc.content2.content4'), },
                { content: t('fccc.content2.content5'), },
                { content: t('fccc.content2.content6'), },
            ],    
        },
    ];
    const listEn = [
        { 
            title: t('fccc.title1'),
            contentList: [
                { content: t('fccc.content1.content1'), },
                { content: t('fccc.content1.content2'), },
                { content: t('fccc.content1.content3'), },
                { content: t('fccc.content1.content4'), },
            ],    
        },
        { 
            title: t('fccc.title2'),
            contentList: [
                { content: t('fccc.content2.content1'), },
                { content: t('fccc.content2.content2'), },
                { content: t('fccc.content2.content3'), },
                { content: t('fccc.content2.content4'), },
                { content: t('fccc.content2.content5'), },
                { content: t('fccc.content2.content6'), },
                { content: t('fccc.content2.content7'), },
            ],    
        },
        { 
            title: t('fccc.title3'),  
        },
        { 
            contentList: [
                { img: require('../../img/about_fccc_en.png') },
                { content: t('fccc.content3') },
            ]
        },
    ]

    useEffect(() => {
        document.title = t('menu.thirdMenu.title2')
    }, [])

    return <>
        <div className="public_header">
            <div className="public_header_title">{t('menu.thirdMenu.title2')}</div>
        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('menu.thirdMenu.title2')}</div>
            </div>
            {
                t('lang') == 'cn'
                ?   <>
                    <div className="bg-fff">
                        <ArticleComs list={list} />
                        <div className="about_FCCC_content">
                            <img src={require('../../img/aboutFCCC.png')} alt="" />
                        </div>
                    </div>
                </>
                :   <>
                    <div className="bg-fff">
                        <ArticleComs list={listEn} />
                        {/* <div className="about_FCCC_content">
                            <img src={require('../../img/aboutFCCC.png')} alt="" />
                        </div> */}
                    </div>
                </>
            }
            
        </div>
    </>   
}

export default AboutFCCC;
