import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import './index.scss';

const Sponsorship = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        [ 
            { img: require('../../img/partner/logo1.png') } ,
            { img: require('../../img/partner/logo2.png') } ,
        ],
        [ 
            { img: require('../../img/partner/logo3.png') } ,
            { img: require('../../img/partner/logo4.png') } ,
            { img: require('../../img/partner/logo5.png') } ,
        ],
        [ 
            { img: require('../../img/partner/logo6.png') } ,
            { img: require('../../img/partner/logo7.png') } ,
            { img: require('../../img/partner/logo8.png') } ,
            { img: require('../../img/partner/logo9.png') } ,
        ],
        [ 
            { img: require('../../img/partner/logo10.png') } ,
            { img: require('../../img/partner/logo11.png') } ,
            { img: require('../../img/partner/logo12.png') } ,
        ],
        [ 
            { img: require('../../img/partner/logo13.png') } ,
            { img: require('../../img/partner/logo14.png') } ,
        ],
        [
            
            { img: require('../../img/partner/logo15.png') } ,
        ]
    ]

    useEffect(() => {
        document.title = t('menu.thirdMenu.title11')
    }, [])

    return <>
        <div className="partner_header">
            <div className="partner_header_title">{t('menu.thirdMenu.title11')}</div>
        </div>
        <div className="partner_box">
            <div className="public_menu b-b-1-D9D9D9 mb-16">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> {'>'} {t('menu.thirdMenu.title11')}</div>
            </div>
            <div className="partner_box_model">
                <div className="partner_box_model_title">{t('menu.thirdMenu.title11')}</div>
                {
                    list.map((item, index) => {
                        return <ul key={index} className={index > 0 ? "partner_box_model_content_negative partner_box_model_content" : "partner_box_model_content"}>
                            {
                                item.map((citem, cindex) => {
                                    return <li key={cindex} className={index === 2 && cindex === 1 ? "partner_box_model_content_remove" : ""}>
                                        <img src={citem.img} alt="" style={{ width: "215px", height: "215px" }} />
                                    </li>
                                })
                            }
                        </ul>
                    })
                }
            </div>
        </div>
    </>   
}

export default Sponsorship;