import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const EventsTopCityClassicRallyChina = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        {
          contentList: [
            { content: '', img: require('../../img/pulling_logo.png') },
            { content: t('classicRally.content1'), img: require('../../img/pulling1.jpg') }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content2'), imgList: [
                { img: require('../../img/pulling2.jpg'), width: 450 },
                { img: require('../../img/pulling3.jpg'), width: 450 },
            ] }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content3'), }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content4'), imgList: [
                { img: require('../../img/pulling4.jpg'), width: 450 },
                { img: require('../../img/pulling5.jpg'), width: 450 },
            ] }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content5'), imgList: [
                { img: require('../../img/pulling6.jpg'), width: 450 },
                { img: require('../../img/pulling7.jpg'), width: 450 },
            ] }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content6'), imgList: [
                { img: require('../../img/pulling8.jpg'), width: 450 },
                { img: require('../../img/pulling9.jpg'), width: 450 },
            ] }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content7'), }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content8') }
          ],  
        },
        {
          contentList: [
            { content: t('classicRally.content9'), }
          ],  
        },
    ];

    useEffect(() => {
      document.title = t('classicRally.title')
  }, [])

    return <>
        <div className="public_header">
          <div className="public_header_title">{t('classicRally.title')}</div>
        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('classicRally.title')}</div>
            </div>
            <div className="bg-fff">
                <ArticleComs list={list} />
            </div>
        </div>
    </>   
}

export default EventsTopCityClassicRallyChina;
