
import React from "react";

const ArticleComs = ({ list }) =>
{
    return <div className="article_wrap">
        {
            list.map((item, index) => {
                return <ul key={index} className="article_item_box">
                    {
                        item.title && item.title !== ''
                        ?   <li className="article_item_title">{item.title}</li>
                        :   <></>
                    }
                    {
                        item.contentList && item.contentList.length
                        ?   item.contentList.map((citem, cindex) => {
                                return <li key={cindex} className="article_item_content">
                                    {
                                        citem.contentType
                                        ?   <>
                                                {
                                                    citem.contentType === 'render'
                                                    ?   <div className={citem.img || citem.imgList ? "mb-20 article_item_content_text" : "article_item_content_text"} dangerouslySetInnerHTML={{ __html: citem.content }}></div>
                                                    :   <></>
                                                }
                                                {
                                                    citem.contentType === 'function'
                                                    ?   <div className={citem.img || citem.imgList ? "mb-20 article_item_content_text" : "article_item_content_text"}>
                                                        {citem.content()}
                                                    </div>
                                                    :   <></>
                                                }
                                            </>
                                        :   <div className={citem.img || citem.imgList ? "mb-20 article_item_content_text" : "article_item_content_text"}>{citem.content}</div>
                                    }
                                    {
                                        citem.img
                                        ?   <img src={citem.img} alt="" />
                                        :   <></>
                                    }
                                    {
                                        citem.imgList && citem.imgList.length
                                        ?   <div className="d-f article_item_content_imglist_wrap">
                                            {
                                                citem.imgList.map((imgItem, imgIndex) => {
                                                    return <img key={imgIndex} src={imgItem.img} alt="" width={imgItem.width} height={imgItem.height} />
                                                })
                                            }
                                        </div>
                                        :   <></>
                                    }
                                </li>
                            })
                        :   <></>
                    }
                </ul>
            })
        }
    </div>
}

export default ArticleComs;