import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const EventsExperienceChina = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        {
            title: t('experience.title1'),
            contentList: [
                { content: t('experience.content1'), },
                { content: '', img: require('../../img/events/events1.png') },
                { content: '', img: require('../../img/events/events2.png') },
            ]
        },
        {
            title: t('experience.title2'),
            contentList: [
                { content: t('experience.content2'), },
                { content: '', img: require('../../img/events/events3.png') },
                { content: '', img: require('../../img/events/events4.png') },
            ]
        },
        {
            title: t('experience.title3'),
            contentList: [
                { content: t('experience.content3'), },
                { content: '', img: require('../../img/events/events5.png') },
            ]
        },
        {
            title: t('experience.title4'),
            contentList: [
                { content: t('experience.content4'), },
                { content: '', img: require('../../img/events/events6.png') },
            ]
        },
        {
            title: t('experience.title5'),
            contentList: [
                { content: t('experience.content5'), },
                { content: '', img: require('../../img/events/events7.png') },
                { content: '', img: require('../../img/events/events8.png') },
                { content: '', img: require('../../img/events/events9.png') },
            ]
        },
        {
            title: t('experience.title6'),
            contentList: [
                { content: t('experience.content6') },
                { content: '', img: require('../../img/events/events10.png') },
                { content: '', img: require('../../img/events/events11.png') },
            ]
        },
        {
            title: t('experience.title7'),
            contentList: [
                { content: t('experience.content7'), },
                { content: '', img: require('../../img/events/events12.png') },
                { content: t('experience.content8'), },
                { content: t('experience.content9'), },
            ]
        },
        {
            contentList: [
                { contentType: 'function', content: () => <>
                    {t('experience.content10')}：
                    <a onClick={() => history.push('/registro?lang=en')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{t('experience.content11')}</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a onClick={() => history.push('/registro?lang=cn')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{t('experience.content12')}</a>
                </>},
                { content: `${t('menu.firstMenu.title4')}：cn1000miglia@connect.com.cn` },
                { content: `<a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://weibo.com/u/7917857464" target="blank">${t('homePage.social.title1')}</a> 
                <a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://v.douyin.com/iMhoLuEP/" target="blank">${t('homePage.social.title2')}</a> 
                <a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://space.bilibili.com/3546673135487037" target="blank">${t('homePage.social.title3')}</a> 
                <a style="text-decoration: underline;color: blue;cursor: pointer;" href="https://www.xiaohongshu.com/user/profile/66308b1e0000000007004905" target="blank">${t('homePage.social.title4')}</a>`, contentType: 'render',  }, 
            ],  
        },
    ];

    useEffect(() => {
        document.title = t('menu.thirdMenu.title5');
    }, [])

    return <>
        <div className="public_header">
        <img src={require('../../img/events/events1.jpg')} alt="" />
            <div className="public_header_title">{t('menu.thirdMenu.title5')}</div>

        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('menu.thirdMenu.title5')}</div>
            </div>
            <div className="bg-fff">
                <ArticleComs list={list} />
            </div>
        </div>
    </>   
}

export default EventsExperienceChina;
