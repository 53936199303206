import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const Csr = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { 
            title: t('social.title1'),
            contentList: [
                { content: t('social.content1'), },
            ],    
        },
        { 
            title: t('social.title2'),
            contentList: [
                { content: t('social.content2'), },
            ],    
        },
        { 
            title: t('social.title3'),
            contentList: [
                { content: t('social.content3'), },
            ],    
        },
        { 
            title: t('social.title4'),
            contentList: [
                { content: t('social.content4'), },
            ],    
        },
    ]

    useEffect(() => {
        document.title = t('menu.thirdMenu.title4')
    }, [])

    return <>
        <div className="public_header">
            <div className="public_header_title">{t('social.title')}</div>
        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('social.title')}</div>
            </div>
            <div className="bg-fff">
                <ArticleComs list={list} />
            </div>
        </div>
    </>   
}

export default Csr;
