import React, { useState } from "react";
import { useHistory } from "react-router";
import { RightOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import '../css/header.scss'



const HeaderPhone = () =>
{
    const { t, i18n } = useTranslation();
    const { pathname } = useLocation();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(0);
    const [activeIndex, setActiveIndex] = useState(window.location.pathname.split('/')[1] == 'en' ? 1 : 0);

    const menuTopList = [
        { title: t('menu.firstMenu.title1'), type: 'newpath', path: '/registro', params: '?lang=cn', newpath: '/registro' },
        { title: t('menu.firstMenu.title2'), type: 'path', path: '/press' },
        { title: t('menu.firstMenu.title3'), type: 'path', path: '/live-streaming' },
        { title: t('menu.firstMenu.title4'), type: 'path', path: '/contacts' },
    ];
    const menuBottomList = [
        { title: t('menu.secondMenu.title1'), type:'path', path: '/' },
        { 
            title: t('menu.secondMenu.title2'),
            children: [
                { title: t('menu.thirdMenu.title1'), type: 'path', path: '/about-us' },
                { title: t('menu.thirdMenu.title2'), type: 'path', path: '/about-FCCC' },
                { title: t('menu.thirdMenu.title3'), type: 'path', path: '/history-of-1000-miglia' },
                { title: t('menu.thirdMenu.title4'), type: 'path', path: '/csr' },
            ],
            pathList: ['/about-us', '/about-FCCC', '/history-of-1000-miglia', '/csr'],
        },
        {   
            title: t('menu.secondMenu.title3'),
            children: [
                { title: t('menu.thirdMenu.title5'), type: 'path', path: '/events/1000-miglia-experience-china' },
                { title: t('menu.thirdMenu.title6'), type: 'path', path: '/events/press-conference' },
                { title: t('menu.thirdMenu.title7'), type: 'path', path: '/events/top-city-classic-rally-china' },
                { title: t('menu.thirdMenu.title8'), type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2023/' },
                { title: t('menu.thirdMenu.title9'), type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-china-2024/' },
                { title: t('menu.thirdMenu.title10'), type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-florida-2024/' },
            ],
            pathList: ['/events/1000-miglia-experience-china', '/events/press-conference', '/events/top-city-classic-rally-china'],
        },
        {   
            title: t('menu.secondMenu.title4'), 
            children: [
                { title: t('menu.thirdMenu.title11'), type: 'path', path: '/Sponsorship' },
                { title: t('menu.thirdMenu.title12'), type: 'path', path: '/become-partner' },
            ],
            pathList: ['/Sponsorship', '/become-partner'],
        },
    ];
    const platformList = [
        { img: require('../img/head/weibo.png'), type: 'newlink', href: 'https://weibo.com/u/7917857464' },
        { img: require('../img/head/xiaohongshu.png'), type: 'newlink', href: 'https://www.xiaohongshu.com/user/profile/66308b1e0000000007004905' },
        { img: require('../img/head/douyin.png'), type: 'newlink', href: 'https://v.douyin.com/iMhoLuEP/' },
        { img: require('../img/head/bilibili.png'), type: 'newlink', href: 'https://space.bilibili.com/3546673135487037' },
    ];
    const langList = [
        // { img: require('../img/head/yingguo.png'), type: 'link', href: 'https://1000miglia.it', title: 'Italiano' },
        // { img: require('../img/head/yidali.png'), type: 'link', href: 'https://1000miglia.it/en/', title: 'English' },
        { img: require('../img/head/zhongguo.png'), type:'lang', title: 'China', isActive: true, lang: 'cn' },
        { img: require('../img/head/yidali.png'), type:'lang', title: 'English', lang: 'en' },
    ];
    // const bannerList = [
    //     { title: '1000 MIGLIA EXPERIENCE USA FLORIDA 2025', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-florida-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE CHINA 2024', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-china-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2024', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2023', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2023/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2022', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-jubilee-edition-2022/' },
    //     { title: '1000 MIGLIA EXPERIENCE USA FLORIDA 2025', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-florida-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE CHINA 2024', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-china-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2024', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2023', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2023/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2022', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-jubilee-edition-2022/' },
    //     { title: '1000 MIGLIA EXPERIENCE USA FLORIDA 2025', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-florida-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE CHINA 2024', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-china-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2024', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2024/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2023', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2023/' },
    //     { title: '1000 MIGLIA EXPERIENCE UAE 2022', type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-jubilee-edition-2022/' },
    // ];

    const routerPush = (data) =>
    {
        switch (data.type)
        {
            case 'link':
                setIsOpen(false)
                window.location.href = data.href;
                break;
            case 'newlink':
                setIsOpen(false)
                window.open(data.href, '_blank');
                break;
            case 'path':
                setIsOpen(false)
                history.push({ pathname:`/${t('lang')}${data.path}${data.params ? data.params : ''}`})
                break;
            case 'newpath':
                setIsOpen(false)
                window.open(window.location.origin + `/${t('lang')}` + data.path + `${data.params ? data.params : ''}`, '_blank')
                break;
            case 'lang':
                let arr = window.location.pathname.split('/');
                i18n.changeLanguage(data.lang)
                
                history.push(`/${data.lang}${arr.length > 2 ? ('/' + arr.splice(2).join('/')) : ''}`)
                break;
            default:
                break;
        }
    }

    return <div className="public_header_wrap">
        <div className="wrap_head_top">
            <div className="header_logo" onClick={() => history.push({ pathname:`/${t('lang')}`})}>
                {/* <img src={require('../img/head/logo_new.png')} alt="" /> */}
                {/* <img src={require('../img/head/logo.png')} /> */}
                <img src={require('../img/head/menu_logo.png')} alt="" />
            </div>
            <div className="head_menu_btn">
                <span>菜单</span>
                <div className="head_menu_btn_icon_box">
                    {
                        isOpen
                        ?   <img src={require('../img/head/menu_close_phone.png')} onClick={() => setIsOpen(false)} alt="" />
                        :   <img src={require('../img/head/menu_phone.png')} onClick={() => setIsOpen(true)} alt="" style={{ transform: 'scale(0.8)' }} />
                    }
                </div>
            </div>
        </div>
        {/* <div className="head_banner_box">
            <ul className="d-f">
                {
                    bannerList.map((item, index) => {
                        return <li key={index} onClick={() => routerPush(item)}><span></span>{item.title}</li>
                    })
                }
            </ul>
        </div> */}
        <div className="header_menu" style={{ left: isOpen ? 0 : '-100%' }}>
            <ul className="header_menu_bottom_lang">
                {
                    langList.map((item, index) => {
                        return <li key={index} className={item.isActive ? 'menu_active' : '' }>
                            <img src={item.img} onClick={() => {
                                
                                setActiveIndex(index);
                                routerPush(item);
                                }} title={item.title || ''}  alt="" />
                        </li>
                    })
                }
            </ul>
            <div className="header_menu_bottom">
                <ul className="header_menu_bottom_menu">
                    {
                        menuBottomList.map((item, index) => {
                            return <li key={index} className={`${item.path && pathname === item.path || (item.pathList && item.pathList.includes(pathname)) ? "header_menu_bottom_menu_fisrt_menu header_menu_bottom_menu_fisrt_menu_active" : "header_menu_bottom_menu_fisrt_menu"}`} onClick={() => {
                                if (item.children && item.children.length)
                                {
                                    setIsActive(index)
                                } else {
                                    routerPush(item)
                                }
                            }}>
                                {item.title}
                                {
                                    item.children && item.children.length
                                    ?   <img src={require('../img/head/down_icon_phone.png')} alt="" className="down_icon" onClick={() => setIsActive(index)} />
                                    :   <></>
                                }
                                {
                                    item.children && item.children.length
                                    ?   <ul className={`header_menu_bottom_menu_second ${isActive === index ? "header_menu_bottom_menu_second_active": ""}`}>
                                            {
                                                item.children.map((citem, cindex) => {
                                                    return <li key={cindex} onClick={() => routerPush(citem)}>
                                                        {citem.title} <span><RightOutlined style={{ fontSize: '14px' }} /></span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    :   <></>
                                }
                                
                            </li>
                        })
                    }
                    <li className="header_menu_bottom_menu_fisrt_menu header_menu_top">
                        {
                            menuTopList.map((item, index) => {
                                return <div key={index} onClick={() => routerPush(item)}>{item.title}</div>
                            })
                        }
                    </li>
                </ul>
            </div>
            <ul className="header_menu_top_platform">
                {
                    platformList.map((item, index) => {
                        return <li key={index} onClick={() => routerPush(item)}>
                            <img src={item.img} alt="" />
                        </li>
                    })
                }
            </ul>
        </div>
    </div>
}

export default HeaderPhone;