import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const Press = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { img: require('../img/press/1.png'), title: t('media.title1') },
        { img: require('../img/press/2.png'), title: t('media.title2') },
        { img: require('../img/press/3.png'), title: t('media.title3') },
        { img: require('../img/press/4.png'), title: t('media.title4') },
    ];
    const contactList = [
        { name: t('media.title5'), value: 'press@connect.com.cn' },
        { name: t('media.title6'), value: '赵姗姗 18601193800' },
        // { name: '机构座机电话', value: '021-1234567' },
        // { name: '联络人手机/微信', value: '1311111111' },
    ];

    useEffect(() => {
        document.title = t('menu.firstMenu.title2');
    }, [])
    return <>
        <div className="press_header">
            <img src={require('../img/video.jpg')} alt="" />
            <p>{t('menu.firstMenu.title2')}</p>
        </div>
        <div className="press_box">
            
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('menu.firstMenu.title2')}</div>
            </div>
            <div className="press_title">{t('media.content1')}</div>
            <div className="press_introduce">{t('media.content2')}</div>
            <div className="press_title1">{t('media.content3')}</div>
            <div className="press_contact_model">
                <div className="press_contact_model_title">{t('media.content4')}</div>
                <ul className="press_contact_model_methods">
                    {
                        list.map((item, index) => {
                            return <li key={index}>
                                <img src={item.img} alt="" />
                                <div>{item.title}</div>
                            </li>
                        })
                    }
                </ul>
                <div className="mb-60 press_contact_model_content">{t('media.content5')}</div>
                <div className="press_contact_model_content">{t('media.content6')}</div>
            </div>
        </div>
        <ul className="press_contact_methods mb-60">
            {
                contactList.map((item, index) => {
                    return <li key={index}>{item.name}：{item.value}</li>
                })
            }
        </ul>
    </>
}

export default Press;