import React, { Component } from 'react';
import Header from '../components/header'
import Footer from '../components/footer'
import { debounce } from '../frame/publicMethods'
import HeaderPhone from '../components/header_phone';
import ScrollToTop from './scrollToTop';

class Frame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth,
        };
        this.onhandleResize = debounce(this.handleResize, 200)
    }

    reload = () => {}

    handleResize = () =>
    {
        let width = window.innerWidth;
        this.setState({
            windowWidth: width,
        })
    }

    componentDidMount () 
    {
        window.addEventListener('resize', this.onhandleResize)
    }

    componentWillUnmount ()
    {
        window.removeEventListener('resize', this.onhandleResize)
    }

    render() {

        const { model } = this.props;

        const { windowWidth } = this.state;

        const childrenBody = React.Children.map(this.props.children, child =>
            React.cloneElement(child, { reload: this.reload })
        );

        switch (model) {
            case 'customFooter':
                {
                    return (
                        <div className='sf-wrapper-white'>
                            {
                                windowWidth < 1280
                                ?   <HeaderPhone />
                                :   <Header />
                            }
                            {childrenBody}
                        </div>
                    )
                }
            
            case 'onlyContent':
                {
                    return (
                        <div className='bg-fff'>
                            <ScrollToTop />
                            {childrenBody}
                        </div>
                    )
                }
            default:
                {
                    return (
                        <div className='sf_wrapper'>
                            <ScrollToTop />
                            {
                                windowWidth < 1280
                                ?   <HeaderPhone />
                                :   <Header />
                            }
                            
                            <div className='sf-content-wrap'>
                                {childrenBody}
                            </div> 
                            <Footer />
                        </div>
                    );
                }
        }
    }
}

export default Frame