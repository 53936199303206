import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import '../css/footer.scss'

const  Footer = () =>
{
    const { t } = useTranslation();
    const history = useHistory();
    
    const platformList = [
        { img: require('../img/footer/weibo.png'), type: 'newlink', href: 'https://weibo.com/u/7917857464' },
        { img: require('../img/footer/xiaohongshu.png'), type: 'newlink', href: 'https://www.xiaohongshu.com/user/profile/66308b1e0000000007004905' },
        { img: require('../img/footer/douyin_footer.png'), type: 'newlink', href: 'https://v.douyin.com/iMhoLuEP/' },
        { img: require('../img/footer/bilibili.png'), type: 'newlink', href: 'https://space.bilibili.com/3546673135487037' },
    ];
    const menuTopList = [
        { title: t('menu.firstMenu.title1'), type:'newpath', path: '/registro' },
        { title: t('menu.firstMenu.title2'), type:'path', path: '/press' },
        { title: t('menu.firstMenu.title4'), type:'path', path: '/contacts' },
        { title: t('menu.firstMenu.title3'), type:'path', path: '/live-streaming' },
    ];
    const bottomContent = [
        { 
            title: t('menu.secondMenu.title2'),
            list: [
                { title: t('menu.thirdMenu.title1'), type: 'path', path: '/about-us' },
                { title: t('menu.thirdMenu.title2'), type: 'path', path: '/about-FCCC' },
                { title: t('menu.thirdMenu.title3'), type: 'path', path: '/history-of-1000-miglia' },
                { title: t('menu.thirdMenu.title4'), type: 'path', path: '/csr' },
            ]
        },
        { 
            title: t('menu.secondMenu.title3'),
            list: [
                { title: t('menu.thirdMenu.title5'), type: 'path', path: '/events/1000-miglia-experience-china' },
                { title: t('menu.thirdMenu.title6'), type: 'path', path: '/events/press-conference' },
                { title: t('menu.thirdMenu.title7'), type: 'path', path: '/events/top-city-classic-rally-china' },
                { title: t('menu.thirdMenu.title8'), type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-uae-2023/' },
                { title: t('menu.thirdMenu.title9'), type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-china-2024/' },
                { title: t('menu.thirdMenu.title10'), type: 'link', href: 'https://1000miglia.it/en/events/1000-miglia-experience/1000-miglia-experience-florida-2024/' },
            ]
        },
        { 
            title: t('menu.secondMenu.title4'),
            list: [
                { title: t('menu.thirdMenu.title11'), type: 'path', path: '/Sponsorship' },
                { title: t('menu.thirdMenu.title12'), type: 'path', path: '/become-partner' },
            ]
        },
    ];
    const logoList = [
        { img: require('../img/footer/logo_1.png') },
        { img: require('../img/footer/logo_2.png') },
        { img: require('../img/footer/logo_3.png') },
    ]

    const routerPush = (data) =>
    {
        switch (data.type)
        {
            case 'link':
                window.location.href = data.href;
                break;
            case 'newlink':
                window.open(data.href, '_blank');
                break;
            case 'path':
                history.push({ pathname:`/${t('lang')}${data.path}${data.params ? data.params : ''}`})
                break;
            case 'newpath':
                window.open(window.location.origin + '/' + t('lang') + data.path + `?lang=${t('lang')}`, '_blank')
                break;
            default:
                break;
        }
    }

    return <div className="public_footer_wrap">
        <div className="public_footer">
            <div className="public_footer_menu">
                <ul>
                    {
                        menuTopList.map((item, index) => {
                            return <li key={index} onClick={() => routerPush(item)}>{item.title}</li>
                        })
                    }
                </ul>
            </div>
            <div className="public_footer_content">
                {
                    bottomContent.map((item, index) => {
                        return <div className="public_footer_content_item" key={index}>
                            <div className="public_footer_content_item_title">{item.title}</div>
                            <ul  className={(index % 2 == 1) ? 'public_footer_content_menu_wrap' : ''}>
                                {
                                    item.list.map((citem, cindex) => {
                                        return <li key={cindex} onClick={() => routerPush(citem)}>{citem.title}</li>
                                    })
                                }
                            </ul>
                        </div>
                    })
                }
            </div>
            <div className="public_footer_logo">
                <div className="public_footer_logo_item">
                    <ul className="public_footer_logo_item_platform d-f">
                        {
                            platformList.map((item, index) => {
                                return <li key={index} onClick={() => routerPush(item)} style={{ marginRight: '.9375rem' }}>
                                    <img src={item.img} alt="" />
                                </li>
                            })
                        }
                    </ul>
                    <img src={require('../img/footer/bottom_logo.png')} alt="" className="footer_big_logo" />
                </div>
                <ul className="public_footer_logo_item public_footer_logo_item_icon d-f">
                    {
                        logoList.map((item, index) => {
                            return <li key={index}>
                                <img src={item.img} alt="" />
                            </li>
                        })
                    }
                </ul>
                <div className="public_footer_logo_item_last">
                    <div className="public_footer_logo_item_last_title">1000 Miglia Experience CHN</div>
                    <ul className="public_footer_item_last_left">
                        <li style={{ lineHeight: '20px' }}>Organizer: Federation Classic Car China(FCCC)</li>
                        <li style={{ lineHeight: '20px', marginBottom: '22px' }}>Room 1502，No.4 Building，Time International Office Building，Chaoyang District，Beijing China</li>
                        <li>Email: cn1000miglia@connect.com.cn</li>
                    </ul>
                </div>
            </div>
            
        </div>
        <div className="public_footer_bottom">@ 2024 Copyright 1000 Miglia s.r.. All rights reserved. Photo consent form- Cookie - Privacy</div>
    </div>
}
export default Footer;