import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// import Home from '../home/home'
import HomePage from '../pages/home/homePage'

import Frame from '../frame/frame'
import { Redirect } from 'react-router';
// import Template from "../pages/template";
import { debounce } from '../frame/publicMethods'
import Contacts from "../pages/contact";
import Registration from "../pages/registration";
import Press from "../pages/press";
import Sponsorship from "../pages/partner/sponsorship";
import BecomePartner from "../pages/partner/becomePartner";
import AboutFCCC from "../pages/aboutUs/aboutFCCC";
import eventsPressConference from "../pages/actionEvent/eventsPressConference";
import Csr from "../pages/aboutUs/csr";
import History from "../pages/aboutUs/history";
import About from "../pages/aboutUs/about";
import EventsTopCityClassicRallyChina from "../pages/actionEvent/eventsTopCityClassicRallyChina";
import LiveStreaming from "../pages/liveStreaming";
import EventsExperienceChina from "../pages/actionEvent/eventsExperienceChina";

class FrameRouter extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            windowWidth: window.innerWidth,
        }
        this.onhandleResize = debounce(this.handleResize, 200)
    }

    handleResize = () =>
    {
        let width = window.innerWidth;
        this.setState({
            windowWidth: width,
        })
    }

    componentDidMount () 
    {
        window.addEventListener('resize', this.onhandleResize)
    }

    componentWillUnmount ()
    {
        window.removeEventListener('resize', this.onhandleResize)
    }

    render () {
        // const { windowWidth } = this.state;
        return <>
            <Router basename="/">
                <Route path="/" render={({match}) => {
                    if (window.location.pathname === '/') {
                        return <Redirect to={{ pathname: '/cn'}} />
                    }
                }} />
                <Route path="/:lang" render={({ match }) => {
                    let pathBase = match.path;
                    return (
                        <Router basename="/">
                            <Switch >
                                {/* <FrameRoute exact path={pathBase + '/'} component={ windowWidth < 1300 ? QualitySequencePhone : QualitySequence} /> */}
                                <FrameRoute exact path={pathBase + '/'} component={HomePage} />
                                <FrameRoute exact path={pathBase + '/contacts'} component={Contacts} />
                                <FrameRoute exact path={pathBase + '/press'} component={Press} />
                                <FrameRoute exact path={pathBase + '/live-streaming'} component={LiveStreaming} />
                                <FrameRoute exact path={pathBase + '/Sponsorship'} component={Sponsorship} />
                                <FrameRoute exact path={pathBase + '/become-partner'} component={BecomePartner} />
                                <FrameRoute exact path={pathBase + '/about-FCCC'} component={AboutFCCC} />
                                <FrameRoute exact path={pathBase + '/events/press-conference'} component={eventsPressConference} />
                                <FrameRoute exact path={pathBase + '/csr'} component={Csr} />
                                <FrameRoute exact path={pathBase + '/history-of-1000-miglia'} component={History} />
                                <FrameRoute exact path={pathBase + '/about-us'} component={About} />
                                <FrameRoute exact path={pathBase + '/events/top-city-classic-rally-china'} component={EventsTopCityClassicRallyChina} />
                                <FrameRoute exact path={pathBase + '/events/1000-miglia-experience-china'} component={EventsExperienceChina} />
                                {/* <FrameRoute exact path={pathBase + 'registro'} component={Registration} model="onlyContent" /> */}
                                <FrameRoute exact path={pathBase + '/registro'} component={Registration} />
                                {/*  */}
                            </Switch >
                        </Router>
                    )
                }} />
            </Router>
        </>   
    }
}

const FrameRoute = ({ component: Component, model, ...rest }) => (
    <Route {...rest} render={(props) => (
        
        <Frame model={model} >
            <Component {...props} />
        </Frame>
    )} />
)

export default FrameRouter;