import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import './partner/index.scss';

const LiveStreaming = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { img: require('../img/homePage/bilibili/1.jpg'), link: 'https://www.bilibili.com/video/BV1St411d7Z5', title: '“2018中国国际名城经典车拉力赛”完美收官 捷豹路虎尽展品牌传承与荣耀' },
        { img: require('../img/homePage/bilibili/2.jpg'), link: 'https://www.bilibili.com/video/BV1Es411F7Fp', title: '第五届中国国际名城经典车拉力赛即将启程' },
        { img: require('../img/homePage/bilibili/3.jpg'), link: 'https://www.bilibili.com/video/BV1tt411R74D', title: '大事情，我们在京郊集体穿越了！' },
        { img: require('../img/homePage/bilibili/4.jpg'), link: 'https://www.bilibili.com/video/BV1Fb411N7uv', title: '明年更精彩！“2018中国国际名城经典车拉力赛”完美收官' },
        { img: require('../img/homePage/bilibili/5.jpg'), link: 'https://www.bilibili.com/video/BV1dJ411c7zW', title: '一场精彩纷呈的经典车拉力赛' },
        { img: require('../img/homePage/bilibili/6.jpg'), link: 'https://www.bilibili.com/video/BV13J411w7aX', title: '高朋满座铸就经典 2019“中国国际名城经典车拉力赛”长三角站' },
        { img: require('../img/homePage/bilibili/7.jpg'), link: 'https://www.bilibili.com/video/BV1JJ411T7nh', title: '2019“中国国际名城经典车拉力赛”深圳东部华侨城站' },
        { img: require('../img/homePage/bilibili/8.jpg'), link: 'https://www.bilibili.com/video/BV1dE411R7ve', title: '不要停下脚步 让我们继续“奔驰”！' },
        { img: require('../img/homePage/bilibili/9.jpg'), link: 'https://www.bilibili.com/video/BV11i4y1L7tj', title: '拥抱经典，一路同行 “2020中国国际名城经典车拉力赛”黄山站' },
        { img: require('../img/homePage/bilibili/10.jpg'), link: 'https://www.bilibili.com/video/BV1A54y167na', title: '2020“中国国际名城经典车拉力赛” 用时间追忆美好，让经典铭刻时间' },
    ];

    const routePush = (path, type) =>
        {
            if (type === 'link')
            {
                window.open(path, '_blank');
            }
        }

    useEffect(() => {
        document.title = t('menu.firstMenu.title3')
    }, [])

    return <>
        <div className="partner_header">
            <div className="partner_header_title">{t('menu.firstMenu.title3')}</div>
        </div>
        <div className="partner_box">
            <div className="public_menu b-b-1-D9D9D9 mb-16">
                <img src={require('../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}  className="c-p">{t('menu.secondMenu.title1')}</span> > {t('menu.firstMenu.title3')}</div>
            </div>
            <div className="social_media_inside_box">
                <ul className="social_media_video">
                    {
                        list.map((item, index) => {
                            return <li key={index} onClick={() => routePush(item.link, 'link')}>
                                <img src={item.img} title={item.title} alt="" />
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    </>   
}

export default LiveStreaming;