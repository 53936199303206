import React, { useEffect } from "react";
import { useHistory } from "react-router";
import ArticleComs from "../../components/articleComs";
import { useTranslation } from "react-i18next";
import '../public.scss';

const History = () =>
{
    const history = useHistory();
    const { t } = useTranslation();

    const list = [
        { 
          title: t('gameIntroduce.title1'),
          contentList: [
            { content: t('gameIntroduce.content1'), img: require('../../img/history1.jpg') }
          ],  
        },
        { 
          title: t('gameIntroduce.title2'),
          contentList: [
            { content: t('gameIntroduce.content2'), img: require('../../img/history2.jpg') }
          ],  
        },
        { 
          title: t('gameIntroduce.title3'),
          contentList: [
            { content: t('gameIntroduce.content3'), }
          ],  
        },
        { 
          title: t('gameIntroduce.title4'),
          contentList: [
            { content: t('gameIntroduce.content4'), img: require('../../img/history3.jpg') }
          ],  
        },
        { 
          title: t('gameIntroduce.title5'),
          contentList: [
            { content: t('gameIntroduce.content5') }
          ],  
        },
        { 
          title: t('gameIntroduce.title6'),
          contentList: [
            { content: t('gameIntroduce.content6'), img: require('../../img/history4.jpg') }
          ],  
        },
        { 
          title: t('gameIntroduce.title7'),
          contentList: [
            { content: t('gameIntroduce.content7'), img: require('../../img/history5.jpg') }
          ],  
        },
        { 
          title: t('gameIntroduce.title8'),
          contentList: [
            { content: t('gameIntroduce.content8') },
            { content: t('gameIntroduce.content8-1'), img: require('../../img/history6.jpg') },
          ],  
        },
        { 
          title: t('gameIntroduce.title9'),
          contentList: [
            { content: t('gameIntroduce.content9'), img: require('../../img/history7.jpg') }
          ],  
        },
        { 
          title: t('gameIntroduce.title10'),
          contentList: [
            { content: t('gameIntroduce.content10') },
            { content: t('gameIntroduce.content10-1'), img: require('../../img/history8.jpg') },
          ], 
        },
    ];

    useEffect(() => {
      document.title = t('gameIntroduce.title')
  }, [])

    return <>
        <div className="public_header">
          <img src={require('../../img/history9.jpg')} alt="" />
            <div className="public_header_title">{t('gameIntroduce.title')}</div>
        </div>
        <div className="public_box">
            <div className="public_menu b-b-1-D9D9D9">
                <img src={require('../../img/reback.png')} alt="" onClick={() => history.goBack()} />
                <div><span onClick={() => history.push('/')}>首页</span> > {t('gameIntroduce.title')}</div>
            </div>
            <div className="bg-fff">
                <ArticleComs list={list} />
            </div>
        </div>
    </>   
}

export default History;
